import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Input from "@material-ui/core/Input";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(0, 3),
  },
  question: {
    color: "#405DB6",
    margin: theme.spacing(3, 0, 0, 0),
  },
}));

function TextQuestionComponent(props) {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <React.Fragment>
      <Typography style={{ color: "#405DB6" }}>
        {props.question.mandatory ? <b style={{ color: "red" }}>*</b> : ""}
        {props.index + 1}. {props.question.context_fr}? /{" "}
        {props.question.context}?
      </Typography>
      {props.type === 1 ? (
        <TextField
          multiline
          rows={4}
          style={{ width: "70%", backgroundColor: "#EFF1F7" }}
          variant="outlined"
          value={props.textAnswer}
          onChange={props.changeTextAnswer(props.question.id)}
          disabled={props.disabled}
        />
      ) : (
        <React.Fragment>
          {props.textAnswer ? (
            <Button
              style={{
                backgroundColor: "#3553B21A",
                border: "1px solid #3553B2",
                borderRadius: 5,
                color: "#3553B2",
                minWidth: 80,
                textAlign: "center",
                padding: 5,
              }}
              role="link"
              onClick={() => openInNewTab(props.textAnswer)}
            >
              View
            </Button>
          ) : (
            <div>
              <Input
                id="icon-button-file"
                type="file"
                onChange={props.handleFileChange}
                disabled={props.disabled}
              />
              <Button
                variant="outlined"
                component="span"
                onClick={props.handleUploadFile}
                disabled={props.disabled}
              >
                Upload
              </Button>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function MultiAnswerQuestion(props) {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.question}>
        {props.question.mandatory ? <b style={{ color: "red" }}>*</b> : ""}
        {props.index + 1}. {props.question.context_fr}? /
        {props.question.context}?
      </Typography>
      <FormGroup row>
        {props.question.options.map((option, index) => (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={props.values ? props.values[index] : false}
                onChange={props.changeMultiAnswer(props.question.id, index)}
                disabled={props.disabled}
              />
            }
            label={`${option.fr} / ${option.en}`}
            key={index}
          />
        ))}
      </FormGroup>
    </div>
  );
}

function SingleAnswerQuestion(props) {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.question}>
        {props.question.mandatory ? <b style={{ color: "red" }}>*</b> : ""}
        {props.index + 1}. {props.question.context_fr}? /
        {props.question.context}?
      </Typography>
      <RadioGroup
        aria-label="Gender"
        name="gender1"
        row
        value={props.singleAnswer}
        onChange={props.changeSingleAnswer(props.question.id)}
      >
        {props.question.options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={index}
            control={<Radio />}
            label={`${option.fr} / ${option.en}`}
            style={{ color: "#405DB6" }}
            disabled={props.disabled}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

function AppQuestion(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        {props.questions.map((question, index) =>
          question.type === 1 || question.type === 4 ? (
            <TextQuestionComponent
              question={question}
              index={index}
              key={index}
              textAnswer={props.answers.get(question.id)}
              changeTextAnswer={props.changeTextAnswer}
              type={question.type}
              handleFileChange={props.handleFileChange}
              handleUploadFile={props.handleUploadFile}
              disabled={props.disabled}
            />
          ) : question.type === 2 ? (
            <SingleAnswerQuestion
              question={question}
              index={index}
              key={index}
              singleAnswer={props.answers.get(question.id)}
              changeSingleAnswer={props.changeSingleAnswer}
              disabled={props.disabled}
            />
          ) : (
            <MultiAnswerQuestion
              question={question}
              index={index}
              key={index}
              values={props.answers.get(question.id)}
              changeMultiAnswer={props.changeMultiAnswer}
              disabled={props.disabled}
            />
          )
        )}
      </CardContent>
    </Card>
  );
}

export default AppQuestion;
